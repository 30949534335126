import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/Layout"
import { Main } from "../components/sections/Content"
import { DivDefaultWrapper } from "../components/parts/General"
import { Title } from "../components/parts/General"
import { Link } from "gatsby"
import Seo from "../components/Seo"

const Ul = styled.ul`
  padding: 0;
  list-style: none;

  li {
    margin: 0 0 35px 0;
  }
`

const FAQPage = () => {
  return (
    <Layout>
      <Main>
        <DivDefaultWrapper id="FAQ" as="section">
          <Title>Frequently Asked Questions</Title>
          <Ul>
            <li>
              <h2>When will the soundtrack be released?</h2>
              <p>
                The release date has not been announced yet. Once everything is
                recorded, roughly edited, and ready for final edits and
                mastering, possible release dates will be assessed.
              </p>
            </li>
            <li>
              <h2>Where will I be able to buy or listen to the soundtrack?</h2>
              <p>
                Across the internet at sites big and small - the plan is to make
                it available for purchase on Bandcamp, Steam, iTunes, Amazon,
                etc., and for streaming on a myriad of services (including Spotify). Currently there
                are no plans for a physical release, but it's not out of the
                question.
              </p>
            </li>
            <li>
              <h2>Where can I listen to the soundtrack now?</h2>
              <p>
                The recordings that are currently in the game (which are made
                primarily with sample libraries) are available for listening on
                the{" "}
                <a href="https://soundcloud.com/wildermyth">
                  Wildermyth Soundcloud
                </a>
              </p>
            </li>
            <li>
              <h2>Why is it taking so long to finish?</h2>
              <p>
                Lots of unanticipated setbacks. Mostly due to Candy's personal
                health, the details of which she'd like to keep private. As the
                timeline has changed, individual musicians' availability has
                also shifted, which can make it a little more complicated. Candy
                remains dedicated to completing the project.
              </p>
            </li>
            <li>
              <h2>
                Is there any way to purchase the rewards from the Kickstarter
                campaign?
              </h2>
              <p>
                Not currently - some music boxes were available, and are now
                sold out. There are some surplus pins which may be sold or used
                for giveaways but are not currently available for purchase. New
                production of music boxes may or may not happen in the future,
                but the pins were exclusive for the kickstarter campaign, so
                once they're gone they'll be gone forever.
              </p>
            </li>
            <li>
              <h2>
                Can I use the music in my podcast/D&D campaign/YouTube
                video/other thing?
              </h2>
              <p>
                For let's play videos or streams and other situations where the
                music is included as a part of gameplay footage, yes! For all
                other uses: maybe! Please{" "}
                <Link to="/contact">contact Candy</Link> with information about
                your specific scenario and she'll get back to you.
              </p>
            </li>
            <li>
              <h2>
                Is the soundtrack being released through a publishing company?
              </h2>
              <p>
                When the kickstarter campaign ran, the soundtrack was intended
                to be released through a specific publishing company. After the
                campaign ended and shortly before Candy was about to sign the
                contract, major concerns about the company's integrity were
                publicly revealed and she decided to move forward without them.
                An agreement with another publishing company is not out of the
                question, but the current plan is for Candy to self-publish and
                to distribute the album through a third-party service.
              </p>
            </li>
          </Ul>
        </DivDefaultWrapper>
      </Main>
    </Layout>
  )
}

export default FAQPage

export const Head = () => <Seo title="Frequently Asked Questions" />
